<template>
  <div class="bg-white border-red p-2 p-lg-3 mt-3">
    <h4 class="text-color my-3">Require Field</h4>
    <div class="require-box">
      <!-- <span style="color:black">{{form.required}}</span> -->
      <div v-for="(field, index) in form.required" :key="index">
        <div>
          <b-card>
            <b-row>
              <b-col md="5" sm="12">
                <b-form-input
                  class="w-80"
                  v-model="field.import_column_name"
                  :disabled="true"
                  placeholder=""
                ></b-form-input>
                <button class="icon-plus ml-3" @click="addField(index)">
                  <font-awesome-icon icon="plus" class="text-black" />
                </button>
                <b-form-checkbox
                  inline
                  class="ml-2"
                  v-model="field.is_required"
                  v-if="field.is_update == 1"
                  :value="1"
                  :unchecked-value="0"
                  @change="moveField(field, true)"
                ></b-form-checkbox>
                <b-form-input
                  v-model="field.description"
                  :disabled="true"
                  class="mt-2 w-80"
                ></b-form-input>
              </b-col>
              <b-col md="7" sm="12">
                <template v-for="(item, i) in field.import_column_name_detail">
                  <div class="mb-2 pr-2" :key="i">
                    <b-form-input
                      :class="
                        'position relative mr-2' + field.error &&
                        !item.id &&
                        borderError
                          ? 'border-error '
                          : 'pr-2'
                      "
                      v-model="item.name"
                    />
                    <!-- <div> -->
                    <div
                      class="text-center pl-1 pr-2 position-absolute"
                      :key="'delete-' + i"
                    >
                      <button
                        class="btn-close"
                        @click="deleteField(index, i, item.id)"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'times']"
                          class="text-black"
                        />
                      </button>
                    </div>
                    <!-- </div> -->
                  </div>
                  <!-- </b-col> -->
                  <!-- <b-col md="1" > -->

                  <!-- </b-col> -->
                </template>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </div>
    <h4 class="text-color my-3">Non Require Field</h4>
    <div class="require-box">
      <div v-for="(non, x) in form.nonRequired" :key="x">
        <div>
          <b-card>
            <b-row>
              <b-col md="5" sm="12">
                <b-form-input
                  class="w-80"
                  v-model="non.import_column_name"
                  :disabled="true"
                ></b-form-input>
                <button class="icon-plus ml-3" @click="addNonRequiredField(x)">
                  <font-awesome-icon icon="plus" class="text-black" />
                </button>
                <b-form-checkbox
                  inline
                  class="ml-2"
                  v-model="non.is_required"
                  v-if="non.is_update == 1"
                  :value="1"
                  :unchecked-value="0"
                  @change="moveField(non, false)"
                ></b-form-checkbox>
                <b-form-input
                  v-model="non.description"
                  :disabled="true"
                  class="mt-2 w-80"
                ></b-form-input>
              </b-col>
              <b-col md="7" sm="12">
                <div
                  v-for="(nonrq, j) in non.import_column_name_detail"
                  :key="j"
                  class="mb-2"
                >
                  <b-form-input
                    :class="
                      'position relative' + non.error &&
                      !nonrq.id &&
                      borderError
                        ? 'border-error'
                        : ''
                    "
                    v-model="nonrq.name"
                  />
                  <div class="text-center pl-0 pr-0 position-absolute">
                    <button
                      class="btn-close"
                      @click="deleteNonRequiredField(x, j, nonrq.id)"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'times']"
                        class="text-black"
                      />
                    </button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </div>
    <b-row class="my-3">
      <b-col>
        <b-button
          size="md"
          :href="pageType === 1 ? '/report/customer' : '/report/transaction'"
          class="btn-cancel"
          >Cancel</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="main-color btn-save" size="md" @click="saveForm"
          >Save</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Object,
    },
    pageType: {
      required: true,
      type: Number,
    },
    formReq: {
      required: true,
      type: Object,
    },
    errorBorder: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      valueDetail: "",
      borderError: this.errorBorder,
    };
  },
  methods: {
    addField(index) {
      this.borderError = false;
      this.form.required[index].import_column_name_detail.push({ name: "" });
    },
    deleteField(indexField, indexItem, id) {
      if (id) {
        this.formReq.delete_import_column.push(id);
      }
      this.form.required[indexField].import_column_name_detail.splice(
        indexItem,
        1
      );
    },
    addNonRequiredField(index) {
      this.form.nonRequired[index].import_column_name_detail.push({ name: "" });
    },
    moveField(val, type) {
      this.$emit("moveField", val, type);
    },
    deleteNonRequiredField(indexField, indexItem, id) {
      if (id) {
        this.formReq.delete_import_column.push(id);
      }
      this.form.nonRequired[indexField].import_column_name_detail.splice(
        indexItem,
        1
      );
    },
    getInputDetailRequred(indexField, indexItem, evt) {
      this.form.required[indexField].import_column_name_detail[indexItem] = evt;
    },
    getInputDetailNonRequred(indexField, indexItem, evt) {
      this.form.nonRequired[indexField].import_column_name_detail[indexItem] =
        evt;
    },
    saveForm() {
      this.$emit("saveForm", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.text-color {
  color: #000;
  font-weight: 600;
}
.require-box {
  padding: 10px 20px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
}
::v-deep .card {
  border: none;
  margin-bottom: 0;
}
::v-deep .card-body {
  padding: 0.5rem;
}

.icon-plus {
  padding: 7px;
  border: 1px solid #d8dbe0;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}
.position-absolute {
  position: absolute;
  display: inline-block;
}
.position-relative {
  position: relative;
}
::v-deep .form-control {
  display: inline-block;
}
.btn-close {
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding-top: 4px;
}
.inline {
  display: inline-block;
}
.container-config {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.w-80 {
  width: 80%;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #f0f0f0;
  opacity: 1;
}
.border-error {
  border-color: red;
}
</style>
