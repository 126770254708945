var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white border-red p-2 p-lg-3 mt-3"},[_c('h4',{staticClass:"text-color my-3"},[_vm._v("Require Field")]),_c('div',{staticClass:"require-box"},_vm._l((_vm.form.required),function(field,index){return _c('div',{key:index},[_c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-input',{staticClass:"w-80",attrs:{"disabled":true,"placeholder":""},model:{value:(field.import_column_name),callback:function ($$v) {_vm.$set(field, "import_column_name", $$v)},expression:"field.import_column_name"}}),_c('button',{staticClass:"icon-plus ml-3",on:{"click":function($event){return _vm.addField(index)}}},[_c('font-awesome-icon',{staticClass:"text-black",attrs:{"icon":"plus"}})],1),(field.is_update == 1)?_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"inline":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.moveField(field, true)}},model:{value:(field.is_required),callback:function ($$v) {_vm.$set(field, "is_required", $$v)},expression:"field.is_required"}}):_vm._e(),_c('b-form-input',{staticClass:"mt-2 w-80",attrs:{"disabled":true},model:{value:(field.description),callback:function ($$v) {_vm.$set(field, "description", $$v)},expression:"field.description"}})],1),_c('b-col',{attrs:{"md":"7","sm":"12"}},[_vm._l((field.import_column_name_detail),function(item,i){return [_c('div',{key:i,staticClass:"mb-2 pr-2"},[_c('b-form-input',{class:'position relative mr-2' + field.error &&
                      !item.id &&
                      _vm.borderError
                        ? 'border-error '
                        : 'pr-2',model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('div',{key:'delete-' + i,staticClass:"text-center pl-1 pr-2 position-absolute"},[_c('button',{staticClass:"btn-close",on:{"click":function($event){return _vm.deleteField(index, i, item.id)}}},[_c('font-awesome-icon',{staticClass:"text-black",attrs:{"icon":['fas', 'times']}})],1)])],1)]})],2)],1)],1)],1)])}),0),_c('h4',{staticClass:"text-color my-3"},[_vm._v("Non Require Field")]),_c('div',{staticClass:"require-box"},_vm._l((_vm.form.nonRequired),function(non,x){return _c('div',{key:x},[_c('div',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"5","sm":"12"}},[_c('b-form-input',{staticClass:"w-80",attrs:{"disabled":true},model:{value:(non.import_column_name),callback:function ($$v) {_vm.$set(non, "import_column_name", $$v)},expression:"non.import_column_name"}}),_c('button',{staticClass:"icon-plus ml-3",on:{"click":function($event){return _vm.addNonRequiredField(x)}}},[_c('font-awesome-icon',{staticClass:"text-black",attrs:{"icon":"plus"}})],1),(non.is_update == 1)?_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"inline":"","value":1,"unchecked-value":0},on:{"change":function($event){return _vm.moveField(non, false)}},model:{value:(non.is_required),callback:function ($$v) {_vm.$set(non, "is_required", $$v)},expression:"non.is_required"}}):_vm._e(),_c('b-form-input',{staticClass:"mt-2 w-80",attrs:{"disabled":true},model:{value:(non.description),callback:function ($$v) {_vm.$set(non, "description", $$v)},expression:"non.description"}})],1),_c('b-col',{attrs:{"md":"7","sm":"12"}},_vm._l((non.import_column_name_detail),function(nonrq,j){return _c('div',{key:j,staticClass:"mb-2"},[_c('b-form-input',{class:'position relative' + non.error &&
                    !nonrq.id &&
                    _vm.borderError
                      ? 'border-error'
                      : '',model:{value:(nonrq.name),callback:function ($$v) {_vm.$set(nonrq, "name", $$v)},expression:"nonrq.name"}}),_c('div',{staticClass:"text-center pl-0 pr-0 position-absolute"},[_c('button',{staticClass:"btn-close",on:{"click":function($event){return _vm.deleteNonRequiredField(x, j, nonrq.id)}}},[_c('font-awesome-icon',{staticClass:"text-black",attrs:{"icon":['fas', 'times']}})],1)])],1)}),0)],1)],1)],1)])}),0),_c('b-row',{staticClass:"my-3"},[_c('b-col',[_c('b-button',{staticClass:"btn-cancel",attrs:{"size":"md","href":_vm.pageType === 1 ? '/report/customer' : '/report/transaction'}},[_vm._v("Cancel")])],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"main-color btn-save",attrs:{"size":"md"},on:{"click":_vm.saveForm}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }